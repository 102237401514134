<template>
    <div class="view-full-details-container flex-column">
        <div class="bordered-bottom">
            <ItemSlotComponent :isHero="false" title="Preview" />
        </div>
        <el-scrollbar>
           <div class="view-full-details-container__content">
                <!-- <DetailsHeroComponent :hideDetails="true" :title="selectedCourse?.title" />
                <DetailsDescriptionComponent title="About" :description="selectedCourse?.about" />
                <DetailsDescriptionComponent title="Course aims" :description="selectedCourse?.aims"/> -->

                <DetailTemplateComponent :tag="currentCourse?.about" :referenceId="currentCourse?.referenceId"/>
           </div>
        </el-scrollbar>
    </div>
</template>
<script>
// import DetailsDescriptionComponent from '@/modules/opportunity-details/components/DetailsDescriptionComponent.vue';
// import DetailsHeroComponent from '@/modules/opportunity-details/components/DetailsHeroComponent.vue';
// import ItemSlotComponent from '@/modules/opportunity-details/components/ItemSlotComponent.vue';

import { mapGetters } from 'vuex';

import DetailTemplateComponent from '@/modules/opportunity-details/components/DetailTemplateComponent.vue';
import { OPPORTUNITY_STORE } from '@/store/modules/opportunity';

export default {
  components: {
    // DetailsHeroComponent,
    // DetailsDescriptionComponent,
    // ItemSlotComponent

    DetailTemplateComponent
  },
  props: ['courses', 'selectedCourse'],

  computed: {
    ...mapGetters(OPPORTUNITY_STORE, ['currentCourse']),
  },
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.view-full-details-container {
    width: 160%;
    height: 100%;
    border-bottom: 1px solid rgba(12, 15, 74, 0.1);
    border-right: 1px solid rgba(12, 15, 74, 0.1);
    // padding-left: 1rem;
    border-left: 1px solid rgba(12, 15, 74, 0.1);

    .bordered-bottom {
        // padding-bottom: 10px;
        border-bottom: 1px solid rgba(12, 15, 74, 0.1);
    }
   .el-scrollbar {
        // background: #fff;
        width: 100%;
        // border-radius: 12px;
        box-sizing: border-box;
        padding: 0;
        height: 100%;

        :deep(.el-scrollbar__view) {
           padding: 0;
           padding: 0 1rem;
        //    border-right: 1px solid rgba(12, 15, 74, 0.1);
        }
        :deep(.el-scrollbar__thumb) {
            width: 0;
        }
        // height: calc(100% - 200px);
    }
    &__content {
        display:flex;
        flex-direction: column;
        gap:2rem;
        padding-bottom: 1rem;
    }
}

@include media-md-max-width() {
    .view-full-details-container {
        width: 100%;
        height: auto;
        .bordered-bottom {
            display: none;
        }
    }
}
</style>
