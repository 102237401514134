<template>
    <div class="skills-filter-container">
        <p class="title">{{layoutFor}} courses</p>
        <el-scrollbar>
           <div class="skills-filter-container__content">
               <OpportunityCardItemComponent
               v-for="(item, index) in data"
               :item="item" :key="index"
               :showButton="true"
               :active="item.id === selectedCourse?.id"
               @click-card="selectCourse(item)"
               />
           </div>
        </el-scrollbar>
    </div>
</template>
<script>
import OpportunityCardItemComponent from '@/modules/opportunity-children/components/OpportunityCardItemComponent.vue';

export default {
  props: ['layoutFor', 'data', 'selectedCourse'],
  components: {
    OpportunityCardItemComponent
  },
  methods: {
    selectCourse(course) {
      this.$emit('select-course', course);
    }
  }
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;
.skills-filter-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: 100%;
    .title {
        font-family: Mulish;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
        text-align: left;
        color: #0C0F4A;
        margin: 0;
    }

    .el-scrollbar {
        box-sizing: border-box;
        // border-right: 1px solid rgba(12, 15, 74, 0.1);
        border-radius: 0;

        :deep(.el-scrollbar__view) {
           padding-right: 1rem;
        }
        // height: calc(100% - 200px);
    }
    &__content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding-bottom: 1rem;
    }
}
@include media-md-max-width {
  .skills-filter-container {
    .el-scrollbar {
      border-right: 0;
      padding-right: 0;
      :deep(.el-scrollbar__view) {
          padding-right: 0;
      }
    }
  }
}
</style>
