<template>
    <OpportunityLayoutSlot v-if="hasMainLayoutInitialized">
           <OpportunityHeroComponent layoutFor="courses" @on-search="onSearch"/>
           <OpportunityNoContentComponent v-if="isEmpty"/>
           <!-- <OpportunityRecommendationComponent/> -->
           <CoursesLayoutComponent :search="search"/>
    </OpportunityLayoutSlot>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import { BusinessDetailTypeEnum } from '@/core/packages/shared-library';
import CoursesLayoutComponent from '@/modules/opportunity-children/components/CoursesLayoutComponent.vue';
import OpportunityHeroComponent from '@/modules/opportunity-children/components/OpportunityHeroComponent.vue';
import OpportunityLayoutSlot from '@/modules/opportunity-children/components/OpportunityLayoutSlot.vue';
import OpportunityNoContentComponent from '@/modules/opportunity-children/components/OpportunityNoContentComponent.vue';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
// import OpportunityRecommendationComponent from '@/modules/opportunity-children/components/OpportunityRecommendationComponent.vue';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    OpportunityHeroComponent,
    OpportunityLayoutSlot,
    OpportunityNoContentComponent,
    // OpportunityRecommendationComponent,
    CoursesLayoutComponent
  },
  data() {
    return {
      isEmpty: '',
      search: ''
    };
  },

  created() {
    if (this.user) {
      const { userBusinessDetails } = this.user;

      if (userBusinessDetails.type !== BusinessDetailTypeEnum.SOLETRADER) {
        this.$router.push({ name: 'dashboard' });
      }
    }
  },

  computed: {
    ...mapGetters(USERS_STORE, ['hasMainLayoutInitialized', 'user']),
    ...mapGetters(USER_WORKSTATION, ['userWorkstations', 'activeUserWorkstation']),
  },

  methods: {
    onSearch(value) {
      this.search = value;
    }
  }
});
</script>
<style lang="scss" scoped>
</style>
