<template>
  <div class="course-group-container">
    <p :class="[tab === 'Available' && 'active']" @click="() => setTab('available')">
      <span :class="'active'">
        Available
      </span>
    </p>
    <p :class="[tab === 'Upcoming' && 'active']" @click="() => setTab('upcoming')">
      <span>
        Upcoming
      </span>
    </p>
    <p :class="[tab === 'Completed' && 'active']" @click="() => setTab('completed')">
      <span>
        Completed
      </span>
    </p>
  </div>
  <div class="courses-item-layout" v-if="courseList.length && !initializing">
    <CoursesListVerticalComponent :class="[viewCourseSummary ? 'close' : 'open']" :layoutFor="tab" :data="courseList" :selectedCourse="selectedCourse" @select-course="selectCourse" />
    <div class="preview-layout flex-row" :class="[viewCourseSummary ? 'open' : 'close']">
      <div class="flex-row ai-c show-in-mobile" style="cursor: pointer; gap: .7rem;" @click="toggleCourseSummary">
        <el-icon :size="20"><ArrowLeftBold/></el-icon>
        <p class="default-text">Back</p>
      </div>
      <CoursePreviewComponent :courses="courseList" :selectedCourse="selectedCourse" />
      <OpportunityRightSidebar :data="courseList" :selectedCourse="selectedCourse" showTitle />
    </div>
  </div>
  <div class="loader-container flex-row ai-c jc-c" v-if="initializing">
    <div class="h100" v-if="initializing" v-loading="initializing">
    </div>
  </div>
  <OpportunityNoContentComponent :tag="emptyStateTag" v-if="courseList.length <= 0 && !initializing"/>
</template>
<script>
import { ArrowLeftBold } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import CoursePreviewComponent from '@/modules/opportunity-children/components/CoursePreviewComponent.vue';
import CoursesListVerticalComponent from '@/modules/opportunity-children/components/CoursesListVerticalComponent.vue';
import OpportunityNoContentComponent from '@/modules/opportunity-children/components/OpportunityNoContentComponent.vue';
import OpportunityRightSidebar from '@/modules/opportunity-details/components/OpportunityRightSidebar.vue';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { OPPORTUNITY_STORE } from '@/store/modules/opportunity';
import { OPPORTUNITY_BILLING_STORE } from '@/store/modules/opportunity-billing';

export default defineComponent({
  components: {
    CoursesListVerticalComponent,
    CoursePreviewComponent,
    OpportunityRightSidebar,
    ArrowLeftBold,
    OpportunityNoContentComponent,
  },

  props: ['search'],

  watch: {
    search: {
      immediate: true,
      handler(value) {
        if (value && this.tab) {
          this.searchKeyword = value;
          this.setTab(this.tab.toLowerCase());
        }
      }
    },
    tab: {
      immediate: true,
      handler() {
        this.setEmptyStateTag();
      }
    }
  },

  data() {
    return {
      initializing: false,
      course: {
        title: 'iknowa Air Source Heat Pump Installation Course',
        about: 'This course is designed to allow qualified plumbers and heating engineers to gain the knowledge and skills needed to install Air source heat pumps either by installing new installations or retro fitting existing installations. Completing this course will allow installers to apply and join the MCS Scheme.',
        aims: 'This sfsdf dfdsfsd sdfsdfdsf sdfsdfsd fsd fdsfds fsfsd sdfsd dfs sdfds sdfsd sdfsd sdf sdfds dsf sdfds sfsdf dfdsfsd sdfsdfdsf sdfsdfsd fsd fdsfds fsfsd sdfsd dfs sdfds sdfsd sdfsd sdf sdfds dsf sdfds qualification prepares learners who are looking towards a career in Air Source Heat Pump Installation and gaining the necessary skills for the design, installation, testing, commissioning, handover, servicing and fault-finding of ground and air source heat pump systems in accordance with the latest NOS/QCF criteria and MCS scheme requirements.',
        thumbnail: '@/assets/images/sample-image-three.jpg',
        image: '@/assets/images/sample-picture.jpg',
        hasSelectedDate: null,
      },
      courseList: [],
      selectedCourse: 0,
      tab: '',
      emptyStateTag: '',

      courseState: {
        available: 'Available',
        upcoming: 'Upcoming',
        completed: 'Completed'
      },

      loaded: false,

      searchKeyword: '',

      viewCourseSummary: true
    };
  },

  async created() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params.upcoming) {
      await this.setTab('upcoming');
    } else {
      await this.setTab('available');
    }
  },

  computed: {
    ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),

    ...mapGetters(OPPORTUNITY_STORE, ['currentCourse', 'courses']),
  },

  methods: {
    ...mapActions(OPPORTUNITY_BILLING_STORE, ['setCoursePrice', 'setCourseDetails']),
    ...mapActions(OPPORTUNITY_STORE, ['getCourses', 'setCurrentCourse']),

    setLoaderFalse() {
      setTimeout(() => {
        this.initializing = false;
      }, 300);
    },

    async setTab(tab) {
      this.initializing = true;
      this.tab = this.courseState[tab];

      const isAvailable = (this.tab === this.courseState.available) ? 1 : 0;
      const isUpcoming = (this.tab === this.courseState.upcoming) ? 1 : 0;
      const isCompleted = (this.tab === this.courseState.completed) ? 1 : 0;

      const filter = {
        search: this.searchKeyword
      };

      this.selectedCourse = null;

      if (isAvailable) {
        filter.isAvailable = isAvailable;
        await this.loadCourses(filter);
      }

      if (isUpcoming) {
        filter.upcoming = 1;
        await this.loadCourses(filter);
      }

      if (isCompleted) {
        filter.isCompleted = 1;
        await this.loadCourses(filter);
      }
      this.setLoaderFalse();
    },

    async loadCourses(filter) {
      console.log(filter, 'filter');
      await this.getCourses(filter)
        .then((response) => {
          const { courses } = this;
          this.courseList = response.data.map((item) => {
            const {
              id, name, objective, availabilityLimit, userCourses
            } = item;
            const record = courses.find((x) => x.id === id);
            let hasSelectedDate = null;
            let selectedStartDate = '';
            let isUpcoming = false;

            if (record) {
              hasSelectedDate = record.hasSelectedDate;
              selectedStartDate = record.selectedStartDate;
            }

            if (filter.upcoming || filter.isCompleted) {
              const [userCourse] = userCourses;
              const { userCourseSchedules } = userCourse || [];

              if (userCourseSchedules.length) {
                hasSelectedDate = (userCourseSchedules.length);
                const [mainSchedule] = userCourseSchedules;
                selectedStartDate = mainSchedule.courseSchedule.startDate;
              }

              if (!filter.isCompleted) {
                isUpcoming = filter.upcoming;
              }
            }

            const course = {
              ...item,
              title: name,
              aims: objective,
              hasSelectedDate,
              thumbnail: '@/assets/images/sample-image-three.jpg',
              slotLimit: availabilityLimit,
              isAvailable: filter.isAvailable,
              isCompleted: filter.isCompleted,
              selectedStartDate,
              isUpcoming,
            };

            if (!this.selectedCourse) {
              this.selectCourse(course);
            }

            return course;
          });
        })
        .catch((e) => {
          this.$notify.error({
            message: e || 'Error getting schedules.'
          });
        })
        .finally(() => {
        });
    },

    selectCourse(course) {
      this.selectedCourse = course;
      this.setCurrentCourse(course);

      if (course.price) {
        this.setCoursePrice(course.price);
      }

      if (course.name) {
        this.setCourseDetails({
          title: course.name
        });
      }
      this.toggleCourseSummary();
    },

    toggleCourseSummary() {
      this.viewCourseSummary = !this.viewCourseSummary;
    },
    setEmptyStateTag() {
      if (this.tab === 'Available') {
        this.emptyStateTag = 'No available courses at the moment, please try again later';
      }
      if (this.tab === 'Upcoming') {
        this.emptyStateTag = 'No upcoming courses at the moment.';
      }
      if (this.tab === 'Completed') {
        this.emptyStateTag = 'No completed courses at the moment.';
      }
    }
  }
});

</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;
.courses-item-layout {
  display: flex;
  gap: 0;
  // height: calc(100vh - 412px);
  height: 800px;
  // margin-top: 1rem;
  .skills-filter-container {
    width: 31%;
  }
  .preview-layout {
    // padding-left: 1rem;
    // border-left: 1px solid rgba(12, 15, 74, 0.1);
  }
  // .view-full-details-container {
  //   width: 45%;
  // }
  // .right-sidebar-container {
  //   width: 24%;
  // }
}
.loader-container {
  position: absolute;
  bottom: -95%;
  left: 0;
  right: 0;
  height: 100%;
}

.course-group-container {
  display: flex;
  gap: 2rem;
  position: sticky;
  top: -2px;
  z-index: 2;
  background: #F1F6FB;
  padding-bottom: 1rem;

  p.active {
    color: #0C0F4A;
    font-weight: 700;
    border-bottom: 2px solid #5DB9E7;
    margin-bottom: -2px;
    padding: 7px 0;
  }

  p {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0C0F4A80;
    cursor: pointer;
  }
}
@include media-lg-max-width {
  .courses-item-layout {
    .skills-filter-container {
      width: 50%;
    }
    .view-full-details-container {
      display: block;
      // width: 50%;
    }
    .right-sidebar-container {
      display: none;
    }
  }
}

@include media-md-max-width() {
  .courses-item-layout {
    gap: 2rem;
    height: auto;
    .preview-layout {
      flex-direction: column;
    }
  }
}
@include media-sm-max-width {
  .courses-item-layout {
    .skills-filter-container {
      width: 100%;
    }
    .close {
      display: none;
    }
    .open {
      display: flex;
    }
    // .view-full-details-container {
    //   display: none;
    // }
    // .right-sidebar-container {
    //   display: none;
    // }
  }
}
</style>
